import { render, staticRenderFns } from "./banner-img.vue?vue&type=template&id=6a368e01"
import script from "./banner-img.vue?vue&type=script&lang=js"
export * from "./banner-img.vue?vue&type=script&lang=js"
import style0 from "./banner-img.vue?vue&type=style&index=0&id=6a368e01&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports