export default {
  cfa1: {
    title: "Exceptional Brilliant Care for Every Committed Trader.",
    h1: "Creator Incentive Programme",
    h1wap: "EBC  Creator Incentive Programme",
    text: "EBC encourages producing original contents in finance. EBC launches the Creator Incentive",
    text1:
      "Programme to promote the creation of original content for the benefit of traders.",
    text2:
      "Creators get exclusive rewards to turn their passion into a profession.",
    list: [
      "Top-Tier Global Regulation",
      "EBC Million Dollar Challenge",
      "Competitive Trading Environment",
      "Deep Liquidity Pools",
      "Trading Community ",
      "Order Execution in Milliseconds",
      "Shared Media Resources",
      "Swap-Free",
      "shared media resources",
    ],
    list1: [
      "Professional Instruction",
      "Real-Time Analysis",
      "Economic Indicator Review",
      "Valuable Course",
    ],
  },
  cfa2: "Trust & Respect",
  cfa3: {
    title: "Reward Level",
    tips: "Reward(USD)",
    tips1: "Est For Reference Only",
    reward_list: [360, 880, 1800, 3200, 5400, 9000, "11000+"],
    reward_list_m: ["11000+", 9000, 5400, 3200, 1800, 880, 360],
  },
  cfa4: "EBC Institute ",

  cfa5: "To qualify for application, the creator must have a column to produce quality content such as market analysis and trading instruction, in the form including but not limited to article, report or course.<br/>The creators with submissions found to contain plagiarism will be disqualified.<br/>Creators can apply for bonus reward once the volume of submitted content meets the requirement.<br/>Rewards will be distributed on a monthly basis.<br/>For application, please contact AM or e-mail to mkt@ebc.com<br/>All creators in breach of the rules will be disqualified with all the rewards revoked.<br/>EBC reserves the right to make additions or deletions at any time without prior notification.",
  cfa6: {
    title: "Exceptional Brilliant Care for Every Committed Trader.",
    titlewap: "Exceptional Brilliant Care for Every Committed Trader.",
    desc: "EBC Financial Group (UK) Ltd <br />authorised and regulated by the  FCA<br />Reference Number: 927552",
    desc1:
      "EBC Financial Group (Australia) Pty Ltd<br />authorised and regulated by the ASIC<br />Reference Number:500991",
  },
  lang: "en",
};
