export default {
	"faq-class-name": "",
	"faq-title":"帮助中心",
	"faq-desc": "查询常见问题或直接联系客服，EBC客户支持团队随时恭候您的垂询",
	"faq-search": "请输入搜索关键词",
	"faq-tabs":[
		"注册与开户",
		"出入金",
		"交易相关",
		"交易终端",
		"知识类",
	],
	"faq-data":{
		0: [
			{
				"Q": "如何开始在EBC交易？",
				"A": "完成以下步骤，即可在EBC开始交易：\n1.注册EBC账户\n2.认证个人信息\n3.开设交易账号并存款\n4.登录MT4交易终端交易"
			},
			{
				"Q": "可以使用同一个电子邮箱注册多个EBC账户吗?",
				"A": "不可以。\n一个电子邮箱只能注册一个EBC账户，但一个EBC账户可以创建多个交易账号。"
			},
			{
				"Q": "为什么需要上传资料进行认证？     ",
				"A": "作为一家合规并受监管的公司，我们必须根据主管监管机构的规定进行所有业务操作。这包括收集客户的认证信息，如有效身份证件和最近六个月内的住址证明。"
			},
			{
				"Q": "EBC账户需要哪些资料验证文件?",
				"A": "您需要提供有效的身份证件、手机号码、电子邮箱即可完成验证。"
			},
			{
				"Q": "如何重新提交我的验证文件资料?",
				"A": "按照以下步骤重新提交：\n1.登录客户管理后台\n2.点击“我的账户”-“档案管理”\n可以删除已上传的文件，重新提交验证文件\n重新提交后等待审核即可"
			},
			{
				"Q": "我可以更改我的真实账户个人信息吗?",
				"A": "除了实名信息无法修改外，手机、邮箱、银行卡等信息都可以进行修改"
			},
			{
				"Q": "我的资金和个人资料安全吗？",
				"A": "您的资金安全受到严格保护。EBC在全球范围内受到多个顶级监管机构的监管，并严格遵循法律法规。我们将客户资金分开存放在海外大型银行的独立账户中，确保您的资金和投资收益得到双重保障。同时，我们承诺采取所有必要的安全措施保护您的个人信息。"
			},
			{
				"Q": "有哪些账户类型可以选择？",
				"A": "EBC提供2种账户类型：标准账户和专业账户。<a href=\"/TradingAccount\">点击此处查看账户对比表</a>。"
			},
			{
				"Q": "如何查找我的交易账户账号?",
				"A": "查找交易账户账号按照以下步骤操作：\n1.登录客户管理后台\n2.点击“首页”—“我的账户”\n3.创建账号后将会显示在真实账户/模拟账户下方"
			},
			{
				"Q": "真实账户和模拟交易账户有区别吗? ",
				"A": "两者的交易流程、交易产品和K线走势是一致的。\n真实账户的操作需承担实际的财务风险。\n模拟账户则帮助用户在不涉及真实资金的情况下，学习和理解市场动态。"
			},
			{
				"Q": "EBC办事处位于哪里?",
				"A": "EBC在英国伦敦、东京、悉尼、新加坡、香港等金融中心均设有办事处。"
			},
			{
				"Q": "EBC开户是否有年龄限制？",
				"A": "EBC开户年龄为18-65周岁（日本地区为18-70周岁，更多相关信息可咨询客服）。"
			},
			{
				"Q": "EBC不接受来自哪些国家的客户?  ",
				"A": "目前暂不支持美国、加拿大、马来西亚等国家客户开户，合规展业地区将不定期更新，具体详情可以咨询客户经理。"
			},
			{
				"Q": "如何注销我的EBC账户?",
				"A": "您可以通过联系您的账户经理获得帮助，或者使用注册时的邮箱向cs@ebc.com发送注销账户的申请。\n客服部门会在收到您的请求后进行处理。"
			},
			{
				"Q": "我可以更改杠杆吗?",
				"A": "可以。按照以下步骤操作更改杠杆：\n1.登录客户管理后台\n2.点击“首页”-“我的账户”-账号右侧“设置”-“修改杠杆”\n3.选择适合的杠杆，点击“确定”即可修改成功。"
			},
			{
				"Q": "我可以联系客服人员做什么？工作时间是？",
				"A": "我们的客户服务支持部门全天候（24/7）随时准备解答您在EBC交易过程中遇到的任何问题，无论是注册问题、平台下载，或其他相关疑问。您可以通过在线客服或发送电子邮件至cs @ebc.com与我们联系。"
			},
			{
				"Q": "如果我需要投诉，投诉程序是什么？",
				"A": "如果您需要投诉，请首先联系您的账户经理。他们将调查事件并寻求最快的解决方案。如果您希望进一步投诉请发送邮件并附上投诉内容发送至cs@ebc.com"
			}
		],
		1: [
			{
				"Q": "有哪些入金须知?",
				"A": "EBC支持全天候24*7入金，入金操作存款时须在有效时间内完成付款，否则订单将会自动取消。\n请使用本人银行账户进行入金。\n不同的入金方式可能有不同的限额，请在通过特定通道入金前，仔细查看该通道的相关细则和注意事项。"
			},
			{
				"Q": "有哪些出金须知?",
				"A": "出金时必须使用本人银行账户作为收款账户。同时，请注意，最低出金金额为100美元。"
			},
			{
				"Q": "如何进行入金?",
				"A": "按照以下步骤即可入金：\n1.登录客户管理后台\n2.选择入金渠道，注意入金的最大和最小限额，按照步骤提示完成入金。"
			},
			{
				"Q": "我是否可以用他人的银行卡入金/出金？",
				"A": "EBC不接受第三方付款。所有入金必须来自于您个人名下的账户。（所有入金必须来自于您本人的银行账户。）\n如果我们怀疑资金来源于第三方，我们保留权利将资金退回给汇款人并暂时冻结您的账户余额，直至完成身份证明和资金来源的验证。\n在未验证的情况下，我们也保留冻结您账户余额的权利，此时您将无法提取账户余额。\nEBC不处理向第三方的出金。如遇特殊情况，敬请联系我们。"
			},
			{
				"Q": "入金的限额是多少?",
				"A": "不同支付渠道入金限额不同，请参考渠道备注限额信息。"
			},
			{
				"Q": "EBC收取任何入金或出金手续费吗?",
				"A": "EBC入金以及出金操作均不收取任何手续费\n请注意，一些USDT交易服务提供商可能会收取一定的手续费。"
			},
			{
				"Q": "我可以在工作时间之外的时间出入金吗?",
				"A": "您可以在周末和节假日正常提交出入金申请。但请留意，由于周末非工作日，处理您的申请可能会有所延迟，最晚将在下一个工作日（周一）处理到账。"
			},
			{
				"Q": "出入金需要多长时间处理?",
				"A": "在正常情况下，工作日的入金处理时间大约为1至15分钟\n在工作日下午四点前提交的出金申请，最快可在当天到账。"
			},
			{
				"Q": "可以通过多张银行卡出入金吗?",
				"A": "可以，本人名下银行卡均可"
			},
			{
				"Q": "我可以从仍有持仓的交易账户出金吗?",
				"A": "可以，但请留意账户中必须有足够的资金保障，以避免因出金操作后账户遭受爆仓。\n如果风险控制部门判断出金操作后可能导致账户爆仓，将会拒绝执行出金申请，只有在平仓操作完成后，才能进行出金操作。"
			},
			{
				"Q": "出金时可以使用不同于入金的支付方式吗?",
				"A": "出金时，本金部分必须通过与入金相同的方式进行操作，而盈利部分则可以选择其他方式出金。"
			},
			{
				"Q": "为什么出/入金失败了?",
				"A": "出/入金失败可能是由以下几个原因造成的：\n1.使用了非本人的银行卡进行出入金操作\n2.银行卡被冻结或处于非正常状态。\n3.在进行转账时，银行信息或出金账户信息填写有误。\n如果遇到上述情况，请及时联系官方在线客服以获得协助处理。"
			},
			{
				"Q": "我的同名账户之间可以互转资金吗?",
				"A": "可以。同名账户内转秒到账。"
			},
			{
				"Q": "为什么提交一笔出金之后无法提交第二笔？",
				"A": "需等待第一笔出金审核成功后，即可提交第二笔出金。"
			},
			{
				"Q": "为什么我取消取款后，资金没有回到我的交易账户？",
				"A": "取消取款后资金将自动退回至后台主钱包，将资金从主钱包内转至交易账户即可。"
			}
		],
		2: [
			{
				"Q": "我在EBC可以交易哪些产品?",
				"A": "EBC为客户提供了大量的资本市场产品，因此您只需要一个交易账户就可以创建多元化的投资组合。\n<a href=\"/tradingproduct\">点击此处查看完整的产品列表</a>。"
			},
			{
				"Q": "各账户类型的最大和最小交易量是多少?",
				"A": "STD账户和PRO账户的最大交易量是40手，最小交易量因交易品种而异。<a href=\"/TradingAccount\">点击此处查看账户规格</a>。"
			},
			{
				"Q": "什么是市价成交？什么是挂单交易？",
				"A": "市价成交，是指订单立即执行成交，投资者按照当前市场可用的最优价格买进或卖出；\n挂单交易，是一种预约执行，投资者输入预期的买卖价格，当市场价格达到或超过预期价格时，交易系统便会自动执行交易。"
			},
			{
				"Q": "什么是buy limit，buy stop，sell limit，sell stop？",
				"A": "Buy Limit：当价格下跌至预定较低水平时买入，适合低价买入策略。\n例如，若预计金价从2000下跌至1980再上涨，可设置1980的Buy Limit，等待低价买入。\nSell Limit：当价格上涨至预定较高水平时卖出，适合高价卖出策略。\n例如，若预计金价从2000上涨至2020再下跌，可设置2020的Sell Limit，等待高价卖出。\nBuy Stop：当价格上涨至预定较高水平时买入，适合追涨策略。\n例如，若认为金价超过2020才能确认上升趋势，可设置2020的Buy Stop，等待向上突破后买入。\nSell Stop：当价格下跌至预定较低水平时卖出，适合追跌策略。\n例如，若认为金价跌破1980才能确认下跌趋势，可设置1980的Sell Stop，等待向下跌破后卖出。"
			},
			{
				"Q": "爆仓比例是多少?",
				"A": "爆仓比例为30%。"
			},
			{
				"Q": "如何设置止盈和止损？",
				"A": "在电脑版MT4平台上，设置止损止盈的步骤如下：\n1.选择您想要管理的交易订单。\n2.使用鼠标右键点击该订单，选择“修改或删除订单”。\n3.在出现的窗口中设置“止损价”和“获利价”\n\n在移动版MT4平台上，设置止损止盈的步骤如下：\n1.在“交易”页面找到您要修改的持仓订单\n2.点击或长按该订单，在弹出菜单中选择“修改”\n3.在出现的窗口中设置“止损价”和“获利价”"
			},
			{
				"Q": "什么是锁单?",
				"A": "锁单是相同品种、相同手数，但相反方向的开仓单。"
			},
			{
				"Q": "EBC提供交易信号吗?",
				"A": "EBC不提供交易信号，但EBC智能跟单社区入驻众多优质信号方，支持AI智能为您一键筛选目标信号。"
			},
			{
				"Q": "平台是否支持EA或其它智能交易系统?",
				"A": "支持。绑定交易终端即可使用。"
			}
		],
		3: [
			{
				"Q": "我应该使用哪个交易终端?",
				"A": "EBC支持使用MT4交易终端，<a href=\"/MT4\">点击此处立即下载</a>。"
			},
			{
				"Q": "我应该使用MT4平台哪个服务器？",
				"A": "EBC实盘账户使用服务器EBCFinancialGroupKY-Live，模拟账户使用服务器EBCFinancialGroupKY-Demo"
			},
			{
				"Q": "为什么我登录交易终端失败?",
				"A": "登录交易平台失败可能由于密码输入错误或网络连接故障引起。遇到登录问题时，建议重新输入密码或检查网络连接。"
			},
			{
				"Q": "无法登录 MT4，为什么出现了“无效账户“错误?",
				"A": "当出现“无效账户”提示时，可能是因为输入了错误的服务器信息或密码不正确。通过选择正确的服务器并正确输入密码，即可解决此问题。"
			},
			{
				"Q": "为什么会显示“无连接”?",
				"A": "可能是因为服务器断开连接或本地网络出现问题。\n首先，请确认本地网络是否能正常浏览网页\n如果网络正常，尝试切换到不同的数据中心（DC）服务器重新连接。"
			},
			{
				"Q": "为什么“新订单”按键是灰色的?",
				"A": "无法创建新订单可能有以下原因：\n1.账户未连接成功或已掉线。\n2.使用的是只读密码登录，这类密码仅允许查看账户，无法进行交易操作。\n3.STD账户仅能交易带有“.s”后缀的品种，而灰色区域内的品种不可交易。"
			},
			{
				"Q": "如何添加或移除交易品种?",
				"A": "PC端添加品种：\n1.登录交易终端，任意位置右键点击。\n2.选择“交易品种”。\n3.选取目标代码组。\n4.双击加入“市场报价”。\nPC端移除品种：\n1.右键需隐藏品种。\n2.选择“隐藏”。\n\n移动端添加品种：\n1.登录，点击右上角“+”。\n2.选择代码组。\n3.点击“+”添加。\n移动端移除品种：\n1.点击左上方笔形按钮。\n2.勾选移除品种，点击删除。"
			},
			{
				"Q": "如何设置买价线?",
				"A": "PC端：\n1.在图表上任意位置右键点击。\n2.选择“属性”进入显示设置。\n3.勾选“买价线”选项。\n\niOS：\n1.打开图表。\n2.点击任意位置后选择“设置”。\n3.勾选“买价线”选项。\n\n安卓：\n1.进入设置菜单。\n2.选择“图表”。\n3.勾选买价线选项以启用。"
			},
			{
				"Q": "能否更改MT4上设置的时区?",
				"A": "不能"
			},
			{
				"Q": "为什么我的挂单没有执行?",
				"A": "挂单未执行可能有以下原因：\n1. 账户可用预付款不足，不足以开仓；\n2. 挂单的价格错误，不符合挂单执行标准；\n3.可能由重大财经事件发布时，市场行情剧烈波动，订单实际成交价格会发生与挂单价格不同的情况。\n请注意，挂单没有执行不仅局限于以上原因，需根据具体情况而定。"
			},
			{
				"Q": "如何查看所有品种的交易时间?",
				"A": "登录MT4交易终端，鼠标右键点击想要查看的交易品种，点击“规格”即可查看"
			},
			{
				"Q": "为什么提示“无效SL/TP”错误?",
				"A": "提示“无效SL/TP”错误可能是以下原因：\n1.现价已超过设置的止盈止损点位，无法成交\n2.STD账户挂单价距离最新价需≥50点"
			},
			{
				"Q": "为什么会出现”市场已关闭”提示?",
				"A": "当市场休市时，则会显示“市场已关闭”。"
			},
			{
				"Q": "我可以部分平仓吗（减少持仓手数）？",
				"A": "您可以部分平仓。当您的现有持仓大于该商品的最小交易手数要求时，在这种情况下可以部分平仓。"
			},
			{
				"Q": "闭市后我还可以交易吗？",
				"A": "当市场关闭后您不可以再交易。您只能在开市的时候交易（开仓，平仓，执行和修改订单）。"
			},
			{
				"Q": "如果我的MT4下线了，需要关闭我开设的所有仓位吗？",
				"A": "如果您关闭MT4交易系统，止盈止损和挂单仍然会保留。\n但是当您关闭MT4交易系统后，您的追踪止损和EA交易系统将会失效的。"
			},
			{
				"Q": "如何查看我的交易历史?",
				"A": "1.登录MT4交易终端\n2.点击导航“终端”-“账户历史”\n3.查看已平仓的交易历史"
			},
			{
				"Q": "我该如何导出交易历史记录?",
				"A": "1.登录MT4交易终端，点击“账户历史”\n2.单击鼠标右键，选择“保存为详细户口结单”"
			},
			{
				"Q": "MT4中资金曲线如何看？",
				"A": "1.打开MT4中账户历史\n2.鼠标右击选择 “保存为详细户口结单”\n3.再点击保存的图即是资金曲线"
			},
			{
				"Q": "PAMM是什么，如何使用？",
				"A": "PAMM即百分比分配管理模式，是一种集合外汇交易资金进行管理的形式。\n投资者可以选择一个合格的交易员或资金经理，使用自己资金和集合资金进行交易以获取利润，并按照约定比例分配利润。"
			}
		],
		4: [
			{
				"Q": "什么是衍生品交易？",
				"A": "衍生品交易市场是目前全球最大的金融交易市场，日均成交量达到约 4 兆美元。\n世界上各货币的汇率都是浮动的，并且在交易时以货币对的形式出现，例如欧元兑美元 (EUR/USD)、\n美元兑日元 (USD/JPY)。它表示在货币对中，买入一种货币的同时卖出另一种货币的交易方式。"
			},
			{
				"Q": "什么是点差？",
				"A": "点差就是某一个交易产品买入价和卖出价之间的差价, 同时也是每一笔交易需要支付的交易成本。"
			},
			{
				"Q": "什么是杠杆?",
				"A": "杠杆指的是投资者能以小比例的资金，投资更大价值的外汇标的，不需要全款交易。杠杆的高低决定占用的保证金的大小。"
			},
			{
				"Q": "什么是保证金（预付款）?",
				"A": "保证金（预付款）是指交易者以杠杆投资的原理，利用资金的一部分来建立头寸，是维持交易头寸的抵押金。\n在市场中，杠杆通常在20-500倍，这使得投资者能以高杠杆活跃地进行交易，当然，保证金制度在成倍放大利润的同时，也增加了交易风险。\n举例：今天赵先生要做等值 100,000 美元的交易。通过保证金交易，假设杠杆比例为500:1，赵先生只需要有 100,000/500＝200美元，便可以进行此交易。\n换句话说，即资金放大了 500 倍，若投资 1 万美元，即可进行500 万美元的交易。"
			},
			{
				"Q": "如何计算开立订单所需的保证金（预付款）?",
				"A": "保证金（预付款）=合约量*现价/杠杆*手数"
			},
			{
				"Q": "交易中的余额、净值和可用保证金有什么关系?",
				"A": "净值=余额－已用预付款（已占用的保证金）±浮动盈损"
			},
			{
				"Q": "为什么会出现负余额情况？",
				"A": "当有极端行情出现时，市场无法给出合理的成交价格，导致最终成交亏损额超出账户的余额和保证金，账户会出现负余额的情况。"
			},
			{
				"Q": "保证金（预付款）和可用保证金（可用预付款）之间有何区别?",
				"A": "保证金（预付款）是指经纪商持有的、用于维持持仓订单的资金。\n账户中剩余的净值则称为可用保证金（可用预付款）。"
			},
			{
				"Q": "市场价格的滑点是如何发生的?",
				"A": "在市场上，如果可成交数量不足以满足当前价格的订单，剩余部分将以下一个价格成交，这种价格差异被称为滑点。\n滑点可能对投资者有利（正滑点）或不利（负滑点），取决于成交价格与预期相比是更好还是更差。"
			},
			{
				"Q": "杠杆对爆仓有什么影响?",
				"A": "杠杆可以放大投资收益，同时也增加了风险。\n杠杆越高，所占用的保证金就会越少，资金的利用率越大，可交易的仓位就会越大，当市场波动过大，保证金不充足，就会触发爆仓。"
			},
			{
				"Q": "什么是隔夜利息?",
				"A": "隔夜利息是交易者在持仓过夜时赚取或支付的利息，于伦敦时间晚上22点计算。\n隔夜利息依交易者持有头寸的方向及相关货币的利率差异而定。\n例如，如果英镑利率远高于日元，持有GBP/JPY的交易者通过做多可以获得利息，反之做空则需支付利息。"
			},
			{
				"Q": "如何查询隔夜利息？",
				"A": "对于合约的隔夜利息查询方法如下：\n1. 登录 MT4 交易终端；\n2. 点击主菜单 “显示” -“交易品种列表”；\n3. 右键想要查看隔夜利息的交易品种，点击 “规格”；\n4. “卖/买单调期库存费” 为隔夜利息。（ps:正隔夜利息会交易商给客户）"
			},
			{
				"Q": "什么是股息，对交易有什么影响",
				"A": "在差价合约（CFD）市场，所谓的“股息”实际上是对持有CFD多头仓位的投资者的利息支付，而持有空头仓位的投资者则需要支付相应的费用。"
			},
			{
				"Q": "黄金、白银现货交易的交易时间？",
				"A": "EBC的现货黄金、白银交易时间为：伦敦时间周日 23:00 至周五 22:00，期间每天的 22:00 至 23:00 市场会有短暂休市。"
			},
			{
				"Q": "黄金、白银现货合约分别等于多少盎司？",
				"A": "黄金现货合约：1 手或 1 张合约 = 100 盎司。\n白银现货合约：1 手或 1 张合约 = 5000 盎司。"
			},
			{
				"Q": "黄金、白银现货交易所需的保证金（预付款）如何计算？",
				"A": "举例：黄金现价为2000美元/盎司，杠杆为500倍，交易0.1手，则黄金所需保证金=2000*100/500*0.1=40USD\n举例：白银现价为23美元/盎司，杠杆为500倍，交易0.1手，则黄金所需保证金=23*5000/500*0.1=23USD"
			},
			{
				"Q": "黄金、白银现货合约有有效期吗？",
				"A": "没有。只要维持账户里有充足的保证金，您的头寸将一直有效，直到您平仓为止。"
			},
			{
				"Q": "布伦特原油（XBRUSD）和西德克萨斯原油（XTIUSD）有何区别？",
				"A": "原油根据API重度可分为轻质原油和重质原油，也可根据硫含量分为含硫原油和低硫原油。\n布伦特原油（XBRUSD）是一种轻质低硫原油，产自北大西洋的几个油区。\n西德克萨斯中质油（XTIUSD）相对较轻且含硫量更低，其报价通常较布兰特原油要低。"
			}
		]
	}
}