export default{
  "screen0": {
    "title": "每個認真交易的人,都值得被認真對待。",
    "enTitle": "Trust & Respect",
    "btn": "觀看全視頻"
  },
  "screen1": {
    "line1": "您可信賴的合作夥伴",
    "line2": "交易世界上",
    "line3": "最受歡迎的資產",
    "line4": "風險提示：差價合約（CFDs）是一種專業且複雜的金融交易產品,由於杠杆原因會帶來較大的損失風險。全球75%的投資者曾出現過虧損。請確保您完全知曉和理解該項投資的性質和所涉及的風險,再來瞭解我們。",
    "list": [{
      "enTitle": "Derivatives",
      "title": "外匯交易",
      "desc": "EBC為您甄選一系列頂級指數,<br />積極探索全球經濟中最有價值和最活躍的領域",
      "more": "瞭解更多"
    }, {
      "enTitle": "Index CFDs",
      "title": "現貨指數差價合約",
      "desc": "最低0點差即可交易36種全球最受歡迎的主要、次要及異國貨幣兌",
      "more": "瞭解更多"
    }, {
      "enTitle": "Commodities",
      "title": "大宗商品",
      "desc": "如果您考慮投資組合多元化,<br />那麼避險資產（如貴金屬）、<br />能源市場（如原油、瓦斯）會是您的理想選擇",
      "more": "瞭解更多"
    }, {
      "enTitle": "Share CFDs",
      "title": "股票差價合約",
      "desc": "交易全球成熟和新興市場36家交易所股票,實时彈性定價,與實際股市表現掛鉤",
      "more": "瞭解更多"
    }]
  },
  "screen2": {
    "title1": "EBC坐擁",
    "title2": "全球<span>兩大頂級監管</span>",
    "desc": "您可享受誠信、安全<br />和效能標準的機构級別交易環境",
    "list": [{
      "title": "英國FCA監管",
      "desc": "EBC Financial Group (UK) Ltd由英國金融行為監管局（FCA）授權和監管。<br />監管號：927552<br/>網址：<a href='https://www.ebcfin.co.uk'>www.ebcfin.co.uk</a>"
    }, {
      "title": "澳大利亞ASIC監管",
      "desc": "EBC Global Pty Ltd由澳大利亞證券和投資委員會（ASIC）授權和監管。<br />監管號：500991"
    }]
  },
  "screen3": {
    "line1": "Your Faithful Partner",
    "line2": "Driving you",
    "line3": "to succeed",
    "line4": "專業領先的金融科技,讓交易無限可能。",
    "line5": "部署於<br />倫敦LD5；紐約NY4；新加坡SG1；東京TY3；香港HK2",
    "line6": "超高速專線連接的獨立服務器",
    "list": [{
        "num": "1000",
        "text": "每秒最高聚合訂單"
      },
      {
        "num": "5",
        "unit": "×24",
        "text": "VIP尊享服務"
      },
      {
        "num": "50",
        "unit": "+",
        "text": "交易品種"
      },
      {
        "num": "20",
        "unit": "+",
        "text": "覆蓋全球超高效能資料中心（DC）"
      },
      {
        "num": "100",
        "unit": "+",
        "text": "全球成員超過"
      },
      {
        "num": "98.75",
        "unit": "%",
        "text": "資料傳輸穩定高達"
      },
      {
        "num": "0.0",
        "unit": "PIPS",
        "text": "同業銀行級別的RAW ECN點差"
      },
      {
        "btn": "探索科技"
      }
    ]
  },
  "screen4": {
    "enTitle": "Discover Prices & Transfer Orders",
    "title": "價格的發現<br />與訂單的傳輸",
    "desc": "EBC通過fix（金融資訊交換協定）將接入的銀行間報價進行聚合,為客戶提供一級流動性場所的直接市場准入,通過專用物理HUB硬體聚合後的報價具有更低的點差和永續交易的深度。",
    "line1": "EBC Trading Black-Box",
    "line2": "交易黑盒",
    "line3": "精准優化拋單算灋",
    "line4": "讓85%以上的訂單成交於更優的價格",
    "list": ["交易黑盒", "交易分佈圖", "經過實驗,我們可以發現：<br />在價格優化率、訂單成交率以及算灋穩定性方面,EBC交易黑盒遠遠優於傳統交易系統"],
    "rightDesc": "EBC通過fix（金融資訊交換協定）將接入的銀行間報價進行聚合,為客戶提供一級流動性場所的直接市場准入,通過專用物理HUB硬體聚合後的報價具有更低的點差和永續交易的深度。",
    "more": "瞭解更多",
    "btn": "開始投資"
  },
  "screen5": {
    "btn": "開始投資",
    "title": "EBC交易空間",
    "enTitle": "EBC Priviate Room",
    "desc": "<span>“清算不是統一的標準,</span>而是適合所有情况的所有答案”",
    "textList": ["Private Room可以隱藏您的部分交易資訊,意味著您下的“大單”可以在一個更好的價格成交,而不存在嚴重的滑點或是因最後觀望價格（Last Look）而遭到訂單拒絕。",
      "如果您是一比特“大交易家”,您不想在市場上讓別人知道您的“底牌”,EBC的Private Room計畫將會是您最優之選。"
    ],
    "ftTitle": "EBC THE FUTURE",
    "ftDesc": "<i><span>EBC</span>面向機構投資者</i>提供一個真正的全球",
    "ftDesc1": "頂級流動性",
    "ftBtn": "與我們合作",
    "ftList": [{
      "title": "安全性",
      "desc": "通過對等網絡的光纖專線連結全球Equinix,實現20毫秒的極速訂單執行。"
    }, {
      "title": "合規性",
      "desc": "我們的資金託管在世界頂級銀行,受到嚴格監管。"
    }, {
      "title": "流動性",
      "desc": "EBC與多家國際頂級銀行建立了長期穩定的合作關係,讓您輕鬆享有超低交易成本。"
    }, {
      "title": "合作夥伴"
    }]
  },
  "urlLan":"ct"
}

